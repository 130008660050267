/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7f714d8f-253f-4d2c-8368-0bdc49eb803c",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_bPGM4sNDr",
    "aws_user_pools_web_client_id": "3854kdt1mnpiso858qbs0mm7lf",
    "oauth": {
        "domain": "project1b0f8e89-infodaddy.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.infodaddy.com/logged_in_page1/",
        "redirectSignOut": "https://www.infodaddy.com/sign_out/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://5oceevaou5dtlpcclzkc4ei2lq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "feb993f7c71e40c286ce2599cc17d283",
    "aws_mobile_analytics_app_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "dynamo3e55eea9-infodaddy",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
